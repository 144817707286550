@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;

.hoca {
  .title {
    background-image: linear-gradient(90deg, v.$color2 ,v.$color1, v.$color2);
    color: v.$color2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.7rem;
    margin-top: 30px;
    font-weight: 700;

    @media(max-width: 768px) {
      font-size: 24px;
  }
   
    
  }
}