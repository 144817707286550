

@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;


$slider-img-height: calc(100vh - 110px);

.slider {



    img {
        height: $slider-img-height;
        object-fit: cover;
        object-position: center center;
    }

    .carousel-caption {
        top: 50%;
        bottom: auto;
        text-align: left center;
        width: 50%;
        color: v.$color1;
        font-weight: bold;
        @include m.transform(translateY(-50%));

        @media (min-height:768px) {
            width: 30%;
            text-align: right center;
        }

        h3{
            font-weight: 900;
        }

        p{
            font-size: 14px;
        }
    }

    
}

