
@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;


.before-after {
    overflow: hidden;
    margin-top: 40px;
    height: 100%;

    .card {
        border-color: v.$color2;
        cursor: pointer;
    }

    .hoca {
        text-align: center;
        background-color: v.$color2;
        
        img{
            
            width: 35%;
            
            @media(max-width: 768px) {
                width: 110%;
                
            }
            @media(max-width: 992px) {
                width: 70%;
                
            }
        }
    }
    

    .card-title{
        color: v.$color1;
        background-color: v.$color2;
        position: absolute;
        bottom: 0 !important;
        left: 0;
        right: 0; 
        text-align: center;
        margin: 0;
        padding-top: 20px;
        
       .image{
        @media (max-width: 992px) {
            width: 50%;
            
           }
       }
    
        h3{
            padding-top: 5px;
            font-weight: 700;
            color: v.$color3
        }

        
        
    }

   

    
}